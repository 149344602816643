import { useAppContext } from '../core/AppProvider';
import { Form } from "react-bootstrap";

export default function FieldSelector ({loadData}) {

	const { user, setUser, } = useAppContext();
	
	let groups = [
		{"name":"reservation", "title":"Reservation"},
		{"name":"passenger", "title":"Passenger"},
		{"name":"notes", "title":"Notes"},
		{"name":"passport", "title":"Passport"},
		{"name":"arrival", "title":"Arrival"},
		{"name":"departure", "title":"Departure"},
		{"name":"emergency", "title":"Emergency"},
		{"name":"extras", "title":"Extras", "detailed_switch": true},
	];

	const setGroupChecked = (groupName, selected, detailed) => {
		let newState = (selected && detailed ? "detailed" : selected);
		setUser({...user, selectedColumnGroups: 
			user.selectedColumnGroups ? {
				...user.selectedColumnGroups, [groupName]: newState
			} : {
				[groupName]: newState
			}
		},()=>{
			//run this after state is set
			if (detailed !== undefined) {
				loadData();
			}
		});
	}

	const getGroupChecked = (groupName, detailed) => {
		if (!user.selectedColumnGroups) { return true; } // default to on if structure DNE
		if (typeof user.selectedColumnGroups[groupName] === "undefined") { return true; } // default to on if not defined
		if (detailed) { //check 'detailed' switch state
			return (user.selectedColumnGroups[groupName] === "detailed"  ? true : false);
		}
		return (user.selectedColumnGroups[groupName] === "detailed" || user.selectedColumnGroups[groupName] === true ? true : false); // if set, return actual state
	}

	/*const Debug = () => {
		return (
			<>
			<xmp>{JSON.stringify(user, null, 4)}</xmp>
			<xmp>{JSON.stringify(search, null, 4)}</xmp>
			</>
		);
	}*/

	return (
		
			<div className="mt-2">
				Fields:&nbsp;&nbsp;&nbsp;&nbsp;
				{groups.map((group, index) => (
					<>
						{
							(group.detailed_switch) ?
								<div style={{display: "inline-block", padding: "5px 8px 5px 8px", borderRadius: "5px", border: "1px solid #ccc"}}>
									<Form.Check style={{display: "inline-block"}} label={group.title} checked={getGroupChecked(group.name)} type="checkbox" inline onClick={(e)=>{setGroupChecked(group.name, e.target.checked)}}/>
									<Form.Check style={{display: "inline-block"}}  type="switch" id="custom-switch" label="Detailed" checked={getGroupChecked(group.name, true)} onClick={(e)=>{setGroupChecked(group.name, getGroupChecked(group.name), e.target.checked)}}/>
								</div>
							:
								<Form.Check label={group.title} checked={getGroupChecked(group.name)} type="checkbox" inline onClick={(e)=>{setGroupChecked(group.name, e.target.checked)}}/>
						}
						{'\u00A0'}
					</>
				))}
			</div>
	);
	
}
//<xmp>{JSON.stringify(user.selectedColumnGroups, null, 4)}</xmp>