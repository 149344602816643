import { useAppContext } from '../core/AppProvider';
import { Col, Row, Container, Button } from "react-bootstrap";
import { initTheme, toggleTheme } from '../core/Theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'

initTheme();
//<FontAwesomeIcon icon={faUser} />
const Header = () => {
	const { logout, user } = useAppContext();
	return (
		<Container fluid>
			<Row>
				<Col className="p-2"><img style={{maxWidth: "120px"}} src="https://www.onthegotours.com/assets/otg/otg-logo-web-22_orange.svg" alt="logo"/></Col>
				<Col style={{textAlign: "center"}} className="p-2"><h2>Supplier Portal</h2></Col>
				<Col className="p-2" style={{textAlign: "right"}}>
					<div style={{paddingBottom: "5px"}}>
						<Button variant="outline-secondary" size="sm" onClick={(e)=>{toggleTheme()}}><FontAwesomeIcon icon={faSun} />&nbsp;/&nbsp;<FontAwesomeIcon icon={faMoon} /></Button>&nbsp;&nbsp;
						<Button variant="outline-secondary" size="sm" onClick={(e)=>{logout()}}>Logout&nbsp;<FontAwesomeIcon icon={faArrowRightFromBracket} /></Button><br/>
					</div>
					{user.email}&nbsp;[{user.companyName && <><b>{user.companyName}</b></>}{user.otgUser && <b>On The Go Tours</b>}]
				</Col>
			</Row>
		</Container>		
	);
}

export default Header;