
import { Routes, Route, Outlet } from 'react-router-dom';
import { useAppContext } from './core/AppProvider';
import Login from './pages/Login';
import Header from './components/Header';
import ErrorNotFound from './pages/ErrorNotFound';
import Dashboard from './pages/Dashboard';
import { Container } from "react-bootstrap";


const Layout = () => {
	return (
		<>
			<Header/>
			<Container fluid>
				<Outlet />
			</Container>
		</>
	)
};


function App() {
	const {user} = useAppContext();
	return (
		<>
			
			{(user && user.jwt) ? (
				<>
					<Routes>
						<Route path="/" element={<Layout/>}>
							<Route path="*" element={<ErrorNotFound/>}/>
							<Route index element={<Dashboard/>}/>
						</Route>
					</Routes>
				</>
			) : (
				<Login/>
			)}
		</>
	);
}

export default App;