import { useState, } from 'react';

import { useAppContext } from '../core/AppProvider';
import { useAppWidgetContext } from '../core/AppWidgetProvider';
import SupplierSelector  from '../components/SupplierSelector';
import FieldSelector  from '../components/FieldSelector';
import PassengerList  from '../components/PassengerList';
import { Form, Card, Row, Col, Button, FloatingLabel, Container } from "react-bootstrap";

export default function Dashboard() {

	const { user, search, setSearch, authFetchJSON, authFetchText } = useAppContext();
	
	const { loading } = useAppWidgetContext();

	const [details, setDetails] = useState({loaded: false, loading:false, data:null});
	
	const queryString = (csv) => {
		let query = [];
		if (search.supplierID && search.supplierID !== '') { query.push({name:"supplierID", value:search.supplierID});	}
		if (search.startDate && search.startDate !== '') { query.push({name:"startDate", value:search.startDate});	}
		if (search.endDate && search.endDate !== '') { query.push({name:"endDate", value:search.endDate});	}
		if (search.reservationID && search.reservationID !== '') { query.push({name:"reservationID", value:search.reservationID});	}
		if (user.selectedColumnGroups && user.selectedColumnGroups.extras && user.selectedColumnGroups.extras !== "detailed") { query.push({name:"collate_extras", value: "true"});}
		if (csv) { query.push({name:"format", value:"csv"}); }
		return query.map(item=>{return item.name+"="+encodeURIComponent(item.value)}).join("&");
	}

	const loadData = async () => {
		let query_string = queryString(false);
		loading(true);
		const data = await authFetchJSON("/api/reservations?"+query_string, null, true);
		if (data) {
			setDetails({loaded:true, loading: false, data});
			loading(false);
		}
	};

	const downloadCSV = async () => {
		let query_string = queryString(true);
		loading(true);
		const data = await authFetchText("/api/reservations?"+query_string, null, true);
		if (data) {
			console.log("csv data",data);
			const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
			console.log(blob)
			const link = document.createElement('a');
			const url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', 'passenger_list.csv');
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			//setDetails({loaded:true, loading: false, data});
			loading(false);
		}
	}

	const Debug = () => {
		return (<></>);
		/*return (
			<>
				User
				<xmp>{JSON.stringify(user, null, 4)}</xmp>
				Search
				<xmp>{JSON.stringify(search, null, 4)}</xmp>
			</>
		);*/
	}

	return (
		<>
			<Debug/>
			<Card className="mb-3">
				<Card.Body>
					<Form>
					<Container>
						<Row className="justify-content-center align-items-center">
							{user.otgUser && <SupplierSelector/> }
							<Form.Group as={Col} controlId="reservationID">
								<FloatingLabel label="Reservation ID">
									<Form.Control label="Reservation ID" size="sm" type="text" onChange={(e)=>{setSearch({...search, reservationID: e.target.value });}} value={search.reservationID}/>
								</FloatingLabel>
							</Form.Group>
							<Form.Group as={Col} controlId="formStartDate">
								<FloatingLabel label="Start Date">
									<Form.Control label="Start Date" size="sm" type="date" onChange={(e)=>{setSearch({...search, startDate: e.target.value });}} value={search.startDate}/>
								</FloatingLabel>
							</Form.Group>
							<Form.Group as={Col} controlId="formEndDate">
								<FloatingLabel label="End Date">
									<Form.Control label="End Date" size="sm" type="date" onChange={(e)=>{setSearch({...search, endDate: e.target.value });}} value={search.endDate}/>
								</FloatingLabel>
							</Form.Group>
							<Col className="align-middle">
								<Button onClick={()=>{loadData()}}>Show Passengers</Button>&nbsp;
								<Button onClick={()=>{downloadCSV()}}>Download CSV</Button>
								
							</Col>
						</Row>
						<Row>
							<Col className="justify-content-center align-items-center"><FieldSelector loadData={loadData}/></Col>
						</Row>
						</Container>
					</Form>
					
				</Card.Body>
			</Card>
			
		

			<PassengerList
				data={details.data}
				loading={details.loading}
				loaded={details.loaded}
			/>
		</>	
	)
};
