import {useState} from 'react';
import { useAppContext } from '../core/AppProvider';
import { useAppWidgetContext } from '../core/AppWidgetProvider';
import { Col, Button, Row, Container, Card, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, } from '@fortawesome/free-solid-svg-icons'

export default function Login() {
	const { setUser } = useAppContext();
	const { loading } = useAppWidgetContext();
	const [showPassword, setShowPassword] = useState(false);
	
	const [details, setDetails] = useState({message: null, email: '', password: ''});
	
	const login = async (event) => {
    	try {
			loading(true);
			//await new Promise(r => setTimeout(r, 2000));
			const res = await fetch(process.env.REACT_APP_API_URL+'/v1/users/login', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({username: details.email, password: details.password})
			});
			loading(false);
			if (res.status === 200) {
				var user = (await res.json());
				if (user.otgUser || user.supplierID) {
					setDetails({...details, message: null});
					setUser(user);
				} else {
					console.log("invalid login token");
					setDetails({...details, message: "Incorrect login details."});
				}
			} else {
				if (res.status === 403) {
					setDetails({...details, message: "Incorrect login details."});
				} else {
					setDetails({...details, message: "Unknown error occured logging in."});
				}
			}
			console.log(res);
		} catch (e) {
			loading(false);
			setDetails({...details, message: "Unknown error occured logging in: "+e.message});
		}
	}

	return (
		<Container>
			<Row className="vh-100 d-flex justify-content-center align-items-center">
			  <Col md={8} lg={6} xs={12}>
						<div style={{textAlign: "center"}}><img style={{maxWidth: "200px"}} src="https://www.onthegotours.com/assets/otg/otg-logo-web-22_orange.svg" alt="logo"/></div>
					  <h1 style={{padding: "10px 0px 10px 0px", textAlign: "center"}} className="fw-bold mb-2">Supplier Portal</h1>
				<Card className="shadow">
				  <Card.Body>
					<div className="mb-3 mt-md-4">
						<div className="mb-3">
							<Form>
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label className="text-center">
										Email address
									</Form.Label>
									<Form.Control type="email" placeholder="Enter email" onChange={(e)=>{setDetails({...details, email:e.target.value})}}/>
								</Form.Group>
		
								<Form.Group className="mb-3" controlId="formBasicPassword">
									<Form.Label>Password</Form.Label>
									<InputGroup>
										<Form.Control type={showPassword?"text":"password"} placeholder="Password" onChange={(e)=>{setDetails({...details, password:e.target.value})}}/>
										<InputGroup.Text onClick={()=>{setShowPassword(!showPassword)}}>{showPassword ? <FontAwesomeIcon icon={faEyeSlash}/> : <FontAwesomeIcon icon={faEye}/> }</InputGroup.Text>
									</InputGroup>
								</Form.Group>
						
								<div className="d-grid">
									<Button variant="primary" type="button" onClick={login}>Login</Button>
								</div>
							</Form>
						</div>
					</div>
				  </Card.Body>
				</Card>
				{details.message && 
						<div className="mt-4" style={{textAlign: "center"}}>
							{details.message}
						</div>
					}
				
			  </Col>
			</Row>
		  </Container>
		
	  );
}
