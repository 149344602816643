//ryan.dutton@testsupplier.com
//3248572837548

import {createContext, useState, useContext, useEffect} from 'react';
//import { useAppWidgetContext } from './AppWidgetProvider';

export const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {

	//const { errorModal } = useAppWidgetContext();

	const getDefaultSearch = () => {
		return {
			supplierID: "",
			startDate: (new Date()).toISOString().slice(0,10),
			endDate: (new Date((new Date()).valueOf() + (2 * 30 * 24 * 60 * 60 * 1000))).toISOString().slice(0,10)
		};
	}

	const [user, setUser] = useStickyState(null, "user");

	const [search, setSearch] = useState(getDefaultSearch());
	
	async function authFetch(url, options, local=false) {
		console.log("current user", user);
		if (user.jwt) {
			// only sends/has the token if the API auth mode is 'bearer'
			options = {...options, headers: {...(options&&options.headers?options.headers:{}), 'Authorization': `${user.jwt}`}}
		}
		return fetch(
			(local?"":process.env.REACT_APP_API_URL)
			+ url,
			options
		);
	}

	async function authFetchText(url, options, local=false) {
		/* START: set max response time */
		const controller = new AbortController();
		const signal = controller.signal;
		if (!options) { options = {}; }
		options = {...options, signal};
		let timeout = setTimeout(() => controller.abort(), 200000);
		/* END: set max response time */
		
		console.log("Loading URL:", url, "with options", options);
		
		let response;
		try {
			response = await authFetch(url, options, local);
		} catch (error) {
			//errorModal("Timeout","Maximum time limit reached while fetching data.");
			return null;
		}
		try {
			const data = await response.text();
			clearTimeout(timeout);
			switch(response.status) {
				case 200:
					return data;
				case 403:
					//errorModal("403 Unauthorized","Access to resource unauthorized, you will be directed to the homepage.", null, "/");
					break;
				case 404:
					//errorModal("404 Not found","API enpdpoint called does not exist.");
					break;
				case 401:
					console.log("401 Unauthorized","Your session timed out, please login again.");
					setUser(null);
					break;
				default:
					//errorModal("Error", "Unknown error fetching data, returning to homepage.", null, "/");
					break;
			}
		} catch (error) {
			throw new Error(error.message);
		}
	}

	async function authFetchJSON(url, options, local=false) {
		/* START: set max response time */
		const controller = new AbortController();
		const signal = controller.signal;
		if (!options) { options = {}; }
		options = {...options, signal};
		let timeout = setTimeout(() => controller.abort(), 200000);
		/* END: set max response time */
		
		console.log("Loading URL:", url, "with options", options);
		
		let response;
		try {
			response = await authFetch(url, options, local);
		} catch (error) {
			//errorModal("Timeout","Maximum time limit reached while fetching data.");
			return null;
		}
		try {
			const data = await response.json();
			clearTimeout(timeout);
			switch(response.status) {
				case 200:
					return data;
				case 403:
					//errorModal("403 Unauthorized","Access to resource unauthorized, you will be directed to the homepage.", null, "/");
					break;
				case 404:
					//errorModal("404 Not found","API enpdpoint called does not exist.");
					break;
				case 401:
					console.log("401 Unauthorized","Your session timed out, please login again.");
					setUser(null);
					break;
				default:
					//errorModal("Error", "Unknown error fetching data, returning to homepage.", null, "/");
					break;
			}
		} catch (error) {
			throw new Error(error.message);
		}
	}

	async function logout() {
		setUser(null);
	}

	const imageDimensions = async (src) => {
		return new Promise((resolve, reject) => {
		  let img = new Image()
		  img.onload = () => resolve({width:img.width, height:img.height})
		  img.onerror = reject
		  img.src = src
		})
	}

	function useStickyState(defaultValue, key) {
		const [value, setValue] = useState(() => {
			const stickyValue = window.localStorage.getItem(key);
			return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
		});
		useEffect(() => {
			window.localStorage.setItem(key, JSON.stringify(value));
		}, [key, value]);
		return [value, setValue];
	}

	function formatBytes(x){
		const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		let l = 0, n = parseInt(x, 10) || 0;
		while(n >= 1024 && ++l){ n = n/1024; }
		return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
	}
	
	const displayDateTime = (timestamp) => {
		let date = new Date.parse(timestamp);
		return date.toLocaleDateString()+" "+date.toLocaleTimeString();
	}

	return (
		<AppContext.Provider value={{ setUser, user, search, setSearch, useStickyState, logout, authFetch, authFetchJSON, authFetchText, formatBytes, displayDateTime, imageDimensions }}>
			{children}
		</AppContext.Provider>
	);

};