import { useAppContext } from '../core/AppProvider';
import { Col } from "react-bootstrap";
import { useState, useEffect, } from 'react';
import { ProgressBar, Form, FloatingLabel } from "react-bootstrap";

//import Form from 'react-bootstrap/Form';

export default function SupplierSelector () {

	const { authFetchJSON, search, setSearch, } = useAppContext();
	
	const [details, setDetails] = useState({loading:true, data:[]});
	
	const loadData = async () => {
		const data = await authFetchJSON('/v1/admin/suppliers?sort_by=asc&sort_field=name');
		if (data) {
			console.log("got suppliers", data);
			setDetails({loading: false, data});
		}
	};
	// eslint-disable-next-line
	useEffect(() => { loadData() }, []);

	const selectSupplier = (supplierID) => {
		setSearch({...search, supplierID });
	}

	return (
		<>
			{(details.loading)?(
				<>
					<ProgressBar className="mb-2"/>
				</>
			):(
				
				<Form.Group as={Col} controlId="formSupplier">
					<FloatingLabel label="Supplier">
						<Form.Select size="sm" onChange={(e)=>{selectSupplier(e.target.value)}} value={search.supplierID?search.supplierID:''}>
							<option value={''}>All</option>
							{details.data.map((supplier) => (
								<option value={supplier.supplierID}>{supplier.name}</option>
							))}
						</Form.Select>
					</FloatingLabel>
				</Form.Group>
								
			)}
		</>
	);
}
