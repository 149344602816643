import { createContext, useState, useContext, } from 'react';

import { Modal, Spinner } from "react-bootstrap";

export const AppWidgetContext = createContext();
export const useAppWidgetContext = () => useContext(AppWidgetContext);

export const AppWidgetProvider = ({ children }) => {

	const [loadingDetails, setLoadingDetails] = useState({visible: false, });

	const loading = (visible) => {
		setLoadingDetails({ ...loadingDetails, visible });
	}

	const Loading = () => {
		return (
			<>
				<Modal show={loadingDetails.visible} onHide={()=>{}} animation={false}></Modal>
				{loadingDetails.visible &&
					<div style={{ zIndex: 100000, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", padding: "10px", borderRadius: "10%", background: "white" }}>
						<Spinner style={{color: "#f06823ff", position: "relative", top: "2px"}} animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				}
			</>
		);	
	}

	return (
		<AppWidgetContext.Provider value={{ loading }}>
			{children}
			<Loading/>
		</AppWidgetContext.Provider>
	);
	
};